import Pusher from "pusher-js"
import { BASE_URL } from "./config"

export const pusher=token=>{
    return new Pusher('4cf86228cb754d84e972', {
        cluster: 'ap1',
        authEndpoint:BASE_URL+"/pusher/auth_user",
        auth: {
            params:{
                token:token
            }
        }
    })
}