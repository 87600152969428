import React, { Component } from 'react'
import { FiSettings } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, remove_login_data } from '../../../config/config'
import {datetime} from "../../../config/datetime"

class Tugas extends Component {
    state={
        tugas:[]
    }

    componentDidMount=()=>{
        this.getAllTugas()
    }
    getAllTugas=()=>{
        API.post("/dashboard/tugas/gets_tugas", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                tugas:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    render() {
        const {tugas}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="card widget-list-tugas">
                                        <div class="card-header d-flex flex-column flex-md-row justify-content-between">
                                                <nav class="nav nav-pills nav-fill bg-white p-1 rounded">
                                                    <Link to="/tugas" className="nav-link active">Semua Tugas</Link>
                                                    <Link to="/tugas_besar" className="nav-link">Semua Tugas Besar</Link>
                                                </nav>
                                                <h5 className="card-title text-truncate d-none d-md-inline-block">Semua Tugas</h5>
                                            </div>
                                            <div className="row">
                                                {tugas.map(task=>(
                                                    <div className="col-md-6">
                                                        <div class="card-body p-4 mb-4">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0">
                                                                    <img src="/img/task.png" className="img-task"/>
                                                                </div>
                                                                <div class="flex-grow-1 ms-4">
                                                                    <div className="d-flex align-items-center text-muted fs-7 mb-2">
                                                                        <FiSettings/>
                                                                        <span className="ms-2">{task.kategori}</span>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <h2 className="fs-5 fw-bold">
                                                                            <Link to={`/tugas/detail/${task.id_tugas}`} className="link-dark">{task.nama_tugas}</Link>
                                                                        </h2>
                                                                    </div>
                                                                    <div className="d-flex flex-column mt-2">
                                                                        <span className="text-muted">Deadline</span>
                                                                        <span class="badge rounded-pill bg-primary align-self-start mt-1 px-3">{datetime(task.deadline)}</span>
                                                                    </div>
                                                                    <div className="d-flex flex-column mt-2">
                                                                        <span className="text-muted fs-7">Progress</span>
                                                                        <div className="w-75 mt-1">
                                                                            <div class="progress" style={{height:"4px"}}>
                                                                                <div class="progress-bar" role="progressbar" style={{width:task.progress+"%"}}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth(Tugas)