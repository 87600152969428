import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging"

export const vapid_key="BKRL_QZFPZN2yhT7kg6-zCwPB5Yfl848bfSlxgPfHFE0U-fZW7JDd6RwLwvn2MCJQT3de1Zppq5G3Bjdt_2OY_s"
const firebaseConfig = {
    apiKey: "AIzaSyAwYiZeCPx_kVg_c6ah4kqXx_zbGSL3KY0",
    authDomain: "garapanid-a602c.firebaseapp.com",
    projectId: "garapanid-a602c",
    storageBucket: "garapanid-a602c.appspot.com",
    messagingSenderId: "677543385771",
    appId: "1:677543385771:web:18611f3dada2d3454635ee",
    measurementId: "G-45BWNELQHK"
}
  

// Initialize Firebase
const app=initializeApp(firebaseConfig);
export const messaging=getMessaging(app)