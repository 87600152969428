import React, { Component } from 'react'
import {Switch, Route, withRouter, Redirect, useLocation} from "react-router-dom"
import { CSSTransition, TransitionGroup } from 'react-transition-group'

//pages
import Login from './login'
import Register from './register'

//routes data
const routes=[
    {path:"/", Component:Login},
    {path:"/register", Component:Register}
]

const Authentication=(props)=>{
    let location=useLocation()

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                timeout={300}
                classNames="tg-zoom"
                unmountOnExit
            >
                <Switch location={location}>
                    {routes.map(({path, Component})=>(
                        <Route key={path} exact path={path}>
                            {({match})=>(
                                <Component/>
                            )}
                        </Route>
                    ))}
                    <Redirect to="/"/>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default withRouter(Authentication)