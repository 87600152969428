import React, { Component } from 'react'
import update from "immutability-helper"
import qs from "querystring"
import { Nav, Tab, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import withAuth from "../../../component/hoc/auth"
import Avatar from '../../../component/ui/avatar'
import { API } from '../../../config/api'
import {BiImageAdd, BiSend, BiTrash} from "react-icons/bi"
import {FiCheck, FiPlus} from "react-icons/fi"
import { access_token, BASE_URL, goto_page, isUndefined, is_login, login_data, password_validate, remove_login_data } from '../../../config/config'
import Dropzone from 'react-dropzone'
import { CustomCheckboxPicker, CustomRadioPicker } from '../../../component/ui/custom_input'
import NumberFormat from 'react-number-format'

class Profile extends Component {
    state={
        user_data:{},
        password:"",
        rekening:[],
        form_validation_update_general:{},
        billing:{},
        riwayat_konfirmasi:[],
        active_riwayat:"",
        konfirmasi_pembayaran:{
            data:[]
        },
        upgrade_paket:{
            is_open:false,
            paket_selected:"premium",
            durasi_langganan:1
        },
        active_tab:"1",
        is_loading:false
    }

    componentDidMount=()=>{
        this.getListRekening()
        if(is_login()){
            this.setState({
                user_data:login_data()
            })
            this.getUserBilling()
            this.getListRiwayatKonfirmasi()
        }
        else{
            goto_page("/")
        }
    }
    updateUserData=()=>{
        this.setState({
            user_data:login_data()
        })
    }
    getUserBilling=()=>{
        API.post("/dashboard/billing/get_user_billing", JSON.stringify({
            token:access_token()
        }))
        .then(res=>{
            this.setState({
                billing:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getListRiwayatKonfirmasi=()=>{
        API.post("/dashboard/billing/list_riwayat_konfirmasi", JSON.stringify({
            token:access_token()
        }))
        .then(res=>{
            this.setState({
                riwayat_konfirmasi:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getListRekening=()=>{
        API.post("/dashboard/billing/list_rekening")
        .then(res=>{
            this.setState({
                rekening:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    setLoading=loading=>{
        this.setState({
            is_loading:loading
        })
    }
    setActiveTab=(tab)=>{
        this.setState({
            active_tab:tab
        })
    }

    //general card
    typeGeneral=(e)=>{
        const target=e.target

        this.setState(prevState=>{
            return {
                ...prevState,
                user_data:{
                    ...prevState.user_data,
                    [target.name]:target.value
                }
            }
        })
    }
    typePassword=e=>{
        const target=e.target

        this.setState({
            password:target.value
        })
    }
    updateGeneral=(e)=>{
        e.preventDefault()

        const {user_data, password}=this.state

        API.post("/dashboard/users/update_general", JSON.stringify({
            token:access_token(),
            nama_lengkap:user_data.nama_lengkap,
            username:user_data.username,
            password:password,
            no_hp:user_data.no_hp
        }))
        .then(res=>{
            const user_data={...login_data(), ...res.data.data}
            localStorage.setItem("user_data", JSON.stringify(user_data))
            this.updateUserData()
            toast.success("Update Profile berhasil!")
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_update_general:err.response.data.status.data
                    })
                    toast.error("Update Profile gagal!")
                break;
            }
        })
    }
    
    //change avatar
    validateImage=(e)=>{
        const form_data=new FormData()
        form_data.append("avatar", e.target.files[0])
        form_data.append("token", access_token())
        form_data.append("avatar_old", this.state.user_data.avatar_url)

        API.post("/dashboard/users/update_avatar", form_data, {
            headers:{
                'content-type':'multipart/form-data'
            }
        })
        .then(res=>{
            const user_data={...login_data(), ...{avatar_url:res.data.data}}
            localStorage.setItem("user_data", JSON.stringify(user_data))
            this.updateUserData()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    //paket
    getPaketDurasi=()=>{
        let durasi
        if(isUndefined(this.state.billing.durasi)){
            durasi=""
        }
        else{
            durasi=this.state.billing.durasi.toString()
        }
        
        if(durasi===""){
            return ""
        }
        else if(durasi==="1"){
            return "Bulanan"
        }
        else if(durasi%12===0){
            return (durasi/12)+" Tahunan"
        }
        else{
            return durasi+" Bulanan"
        }
    }
    getPaketStatus=()=>{
        if(!isUndefined(this.state.billing.status)){
            if(this.state.billing.status==="active"){
                return <span className="badge bg-success badge-rounded">Aktif</span>
            }
            else{
                return <span className="badge bg-danger badge-rounded">Tidak Aktif</span>
            }
        }
        else{
            return <></>
        }
    }
    getKonfirmasiStatus=(status)=>{
        switch(status){
            case "applied":
                return <span className="badge bg-success badge-rounded">Disetujui</span>
            break;
            case "rejected":
                return <span className="badge bg-danger badge-rounded">Ditolak</span>
            break;
            case "confirm":
                return <span className="badge bg-warning badge-rounded">Menunggu Konfirmasi</span>
            break;
        }
    }
    konfirmasiPembayaran=(e)=>{
        e.preventDefault()

        const {konfirmasi_pembayaran}=this.state

        this.setLoading(true)
        API.post("/dashboard/billing/konfirmasi_pembayaran", JSON.stringify({
            token:access_token(),
            bukti_pembayaran:konfirmasi_pembayaran.data
        }))
        .then(res=>{
            this.getListRiwayatKonfirmasi()
            this.setState({
                active_tab:"2",
                konfirmasi_pembayaran:{
                    data:[]
                }
            })
            this.setLoading(false)
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    switch(err.response.data.status.err_code){
                        case "ERR_FIELD_REQUIRED":
                            toast.error("Bukti Pembayaran harus diisi!")
                        break;
                        case "ERR_PAKET_NOT_FOUND":
                            toast.error("Anda belum mengupgrade paket, upgrade paket terlebih dahulu!")
                        break;
                    }
                break;
            }
            this.setLoading(false)
        })
    }

    //upgrade paket
    showUpgrade=()=>{
        this.setState({
            upgrade_paket:{
                is_open:true,
                paket_selected:"premium",
                durasi_langganan:1
            }
        })
    }
    hideUpgrade=()=>{
        this.setState({
            upgrade_paket:{
                is_open:false,
                paket_selected:"premium",
                durasi_langganan:1
            }
        })
    }
    typeUpgrade=(e)=>{
        const target=e.target

        let value
        if(target.name==="durasi_langganan"){
            if(target.value<0 || target.value===""){
                value=0
            }
            else{
                value=target.value
            }
        }
        else{
            value=target.value
        }

        this.setState({
            upgrade_paket:update(this.state.upgrade_paket, {
                [target.name]:{$set:value}
            })
        })
    }
    getUpgradePaketChecked=(paket)=>{
        if(this.state.upgrade_paket.paket_selected===paket){
            return true
        }
        else{
            return false
        }
    }
    upgradePaket=(e)=>{
        e.preventDefault()

        const {upgrade_paket}=this.state

        this.setLoading(true)
        API.post("/dashboard/billing/upgrade_paket", JSON.stringify({
            token:access_token(),
            paket_selected:upgrade_paket.paket_selected,
            paket_durasi:upgrade_paket.durasi_langganan
        }))
        .then(res=>{
            this.getUserBilling()
            this.hideUpgrade()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    toast.error("Data form tidak valid!")
                break;
            }
            this.setLoading(false)
        })
    }

    //file upload
    uploadFiles=(files)=>{
        const form_data=new FormData()

        for(var i=0;i<files.length;i++){
            form_data.append("dokumen["+i+"]", files[i])
        }
        form_data.append("token", access_token())

        API.post("/dashboard/file_manager/upload_file", form_data, {
            headers:{
                'content-type':'multipart/form-data'
            }
        })
        .then(res=>{
            const dokumen=this.state.konfirmasi_pembayaran.data.concat(res.data.dokumen)
            this.setState({
                konfirmasi_pembayaran:update(this.state.konfirmasi_pembayaran, {
                    data:{$set:dokumen}
                })
            })
        })
        .catch(err=>{
            if(err.response.status===401){
               remove_login_data()
            }
        })
    }
    removeFile=(doc)=>{
        API.post("/dashboard/file_manager/remove_file", qs.stringify({
            token:access_token(),
            file_name:doc.path
        }))
        .then(res=>{
            const dokumen=this.state.konfirmasi_pembayaran.data.filter(dok=>dok!==doc)

            this.setState({
                konfirmasi_pembayaran:update(this.state.konfirmasi_pembayaran, {
                    data:{$set:dokumen}
                })
            })
        })
        .catch(err=>{
            if(err.response.status===401){
                remove_login_data()
            }
        })
    }

    //riwayat
    setActiveRiwayat=(id)=>{
        let active_riwayat
        if(this.state.active_riwayat===id){
            active_riwayat=""
        }
        else{
            active_riwayat=id
        }

        this.setState({
            active_riwayat:active_riwayat
        })
    }

    render() {
        const {user_data, password, billing, konfirmasi_pembayaran, is_loading, active_tab, riwayat_konfirmasi, upgrade_paket, active_riwayat, rekening}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5 className="card-title text-truncate d-none d-md-inline-block mb-0">Profile</h5>
                                            </div>
                                            <div class="card-body p-4">
                                                <div class="row mb-3 align-items-center">
                                                    <div class="col-auto">
                                                        <span class="avatar avatar-xl">
                                                            <Avatar data={user_data} circle/>
                                                        </span>
                                                    </div>
                                                    <div class="col">
                                                        <div class="mb-2">
                                                            <button className="btn btn-outline-dark" onClick={()=>this.inputElement.click()}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-md" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"></path><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path><polyline points="7 9 12 4 17 9"></polyline><line x1="12" y1="4" x2="12" y2="16"></line></svg>
                                                                Change Avatar
                                                            </button>
                                                            <input 
                                                                ref={input=>this.inputElement=input}
                                                                type="file" 
                                                                style={{display:"none"}}
                                                                accept=".png, .jpg"
                                                                onChange={this.validateImage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="form-text">membutuhkan gambar berformat .PNG atau .JPG dengan rasio 1:1</span>
                                                <hr/>
                                                <form onSubmit={this.updateGeneral}>
                                                    <div class="mb-2">
                                                        <label class="form-label fw-semibold">E-Mail</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control form-control-center"
                                                            name="email"
                                                            value={user_data.email}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div class="mb-2">
                                                        <label class="form-label fw-semibold">Username</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control form-control-center"
                                                            name="username"
                                                            value={user_data.username}
                                                            onChange={this.typeGeneral}
                                                        />
                                                    </div>
                                                    <div class="mb-2">
                                                        <label class="form-label fw-semibold">Nama Lengkap</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control form-control-center"
                                                            name="nama_lengkap"
                                                            value={user_data.nama_lengkap}
                                                            onChange={this.typeGeneral}
                                                        />
                                                    </div>
                                                    <div class="mb-2">
                                                        <label class="form-label fw-semibold">No. HP</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control form-control-center"
                                                            name="no_hp"
                                                            value={user_data.no_hp}
                                                            onChange={this.typeGeneral}
                                                        />
                                                    </div>
                                                    <div class="mb-2">
                                                        <label class="form-label fw-semibold">Password</label>
                                                        <input 
                                                            type="password" 
                                                            class="form-control form-control-center" 
                                                            value={password} 
                                                            onChange={this.typePassword}
                                                            minLength={8}
                                                        />
                                                        <span className="form-text">Kosongkan Password Apabila tidak Dirubah!</span>
                                                    </div>
                                                    <div class="form-footer d-grid gap-2 mt-4">
                                                        <button type="submit" class="btn btn-primary btn-block">Simpan</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div class="card">
                                            <div class="card-header">
                                                <h5 className="card-title text-truncate d-none d-md-inline-block mb-0">Paket Sekarang</h5>
                                            </div>
                                            <div class="card-body p-4">
                                                <div>
                                                    {this.getPaketStatus()}
                                                </div>
                                                <div className="mt-3 d-flex align-items-center">
                                                    <div className="d-flex flex-column">
                                                        <span className="fs-5 fw-bold text-capitalize">Akun {billing.selected}</span>
                                                        <span className="text-muted">Paket {this.getPaketDurasi()}</span>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <button className="btn btn-primary fw-bold" onClick={this.showUpgrade}>UPGRADE</button>
                                                        <button className="btn btn-outline-dark fw-bold ms-2">BATALKAN</button>
                                                    </div>
                                                </div>
                                                <hr className="mt-4 mb-2"/>
                                                {billing.selected!=="free"&&
                                                    <p className="text-muted">
                                                        Pembayaran anda bulan berikutnya sebesar <strong><NumberFormat value={billing.harga} displayType={"text"} thousandSeparator={true}/></strong> ditagih tanggal <strong>{billing.expired}</strong>
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <div class="card mt-3">
                                            <div class="card-header">
                                                <h5 className="card-title text-truncate d-none d-md-inline-block mb-0">Pembayaran & Konfirmasi</h5>
                                            </div>
                                            <div class="card-body p-4">
                                                <Tab.Container defaultActiveKey={active_tab} activeKey={active_tab}>
                                                    <div>
                                                        <Nav variant="pills">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="1" onClick={()=>this.setActiveTab("1")}>Konfirmasi</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="2" onClick={()=>this.setActiveTab("2")}>Riwayat Pembayaran</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                    <div className="mt-3">
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="1">
                                                                <div>
                                                                    <form class="mb-3 d-flex flex-column" onSubmit={this.konfirmasiPembayaran}>
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <label for="exampleFormControlInput1" class="form-label fw-semibold">Bukti Pembayaran</label>
                                                                            <button type="submit" className="btn btn-success" disabled={is_loading||konfirmasi_pembayaran.data.length===0}>Submit Pembayaran</button>
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            {konfirmasi_pembayaran.data.map(d=>(
                                                                                <div 
                                                                                    className="btn btn-image d-flex align-items-center justify-content-center me-2"
                                                                                >
                                                                                    <img src={BASE_URL+"/"+d.path}/>
                                                                                    <button 
                                                                                        type="button"
                                                                                        className="btn btn-danger btn-remove"
                                                                                        onClick={()=>this.removeFile(d)}
                                                                                    >
                                                                                        <BiTrash/>
                                                                                    </button>
                                                                                </div>
                                                                            ))}

                                                                            <Dropzone onDrop={acceptedFiles=>this.uploadFiles(acceptedFiles)}>
                                                                                {({getRootProps, getInputProps}) => (
                                                                                    <button 
                                                                                        type="button"
                                                                                        className="btn btn-image bg-secondary text-white d-flex align-items-center justify-content-center me-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input 
                                                                                            {...getInputProps()}
                                                                                        />
                                                                                        <BiImageAdd/>
                                                                                    </button>
                                                                                )}
                                                                            </Dropzone>
                                                                        </div>
                                                                        <span className="text-muted mt-2">Format gambar harus JPG atau PNG!</span>
                                                                    </form>
                                                                    <hr/>
                                                                    <h4 className="fw-bold fs-5">List Rekening</h4>
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" width="50">#</th>
                                                                                <th scope="col">No. Rekening</th>
                                                                                <th scope="col" width="100">Tipe</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {rekening.map((rek, i)=>(
                                                                                <tr>
                                                                                    <td scope="col">{i+1}</td>
                                                                                    <td scope="col">{rek.rek_no} ({rek.rek_name})</td>
                                                                                    <td scope="col">{rek.rek_type}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="2">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Created At</th>
                                                                            <th scope="col">Paket Pilihan</th>
                                                                            <th width="80" scope="col">Durasi</th>
                                                                            <th width="120">Harga</th>
                                                                            <th width="100">Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {riwayat_konfirmasi.map((rk, i)=>(
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <button className="btn btn-secondary btn-sm" onClick={()=>this.setActiveRiwayat(rk.id_konfirmasi)}><FiPlus/></button>
                                                                                        </td>
                                                                                    <td>{rk.created_at}</td>
                                                                                    <td>{rk.paket_selected}</td>
                                                                                    <td>{rk.paket_durasi} Bulan</td>
                                                                                    <td><NumberFormat value={rk.paket_harga} displayType={"text"} thousandSeparator={true}/></td>
                                                                                    <td>{this.getKonfirmasiStatus(rk.status)}</td>
                                                                                </tr>
                                                                                {active_riwayat===rk.id_konfirmasi&&
                                                                                    <tr>
                                                                                        <td colSpan="6">
                                                                                            <div className="row">
                                                                                                <div className="col-2 fw-semibold">Keterangan :</div>
                                                                                                <div className="col-12">{rk.keterangan}</div>
                                                                                            </div>
                                                                                            <div className="mt-3">
                                                                                                {rk.bukti_pembayaran.map(d=>(
                                                                                                    <a href={BASE_URL+"/"+d.path} target="_blank"
                                                                                                        className="btn btn-image d-flex align-items-center justify-content-center me-2"
                                                                                                    >
                                                                                                        <img src={BASE_URL+"/"+d.path}/>
                                                                                                    </a>
                                                                                                ))}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* UPGRADE PAKET */}
                <Modal show={upgrade_paket.is_open} onHide={this.hideUpgrade}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Upgrade Plan</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.upgradePaket}>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-sm-6">
                                <CustomRadioPicker
                                    label="Premium"
                                    info="Rp 30.000/Bulan"
                                    checked={this.getUpgradePaketChecked("premium")}
                                    name="paket_selected"
                                    value="premium"
                                    onChange={this.typeUpgrade}
                                />
                            </div>
                            <div className="col-sm-6">
                                <CustomRadioPicker
                                    label="Platinum"
                                    info="Rp 50.000/Bulan"
                                    checked={this.getUpgradePaketChecked("platinum")}
                                    name="paket_selected"
                                    value="platinum"
                                    onChange={this.typeUpgrade}
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label fw-semibold">Durasi Langganan <span className="text-muted">(bulan)</span></label>
                            <input 
                                type="number" 
                                className="form-control"
                                name="durasi_langganan"
                                value={upgrade_paket.durasi_langganan}
                                onChange={this.typeUpgrade}
                            />
                        </div>
                        <div className="text-muted">
                            1. Saat anda mengupgrade paket, paket sebelumnya akan hangus!<br/>
                            2. Paket yang belum dikonfirmasi akan memiliki fitur <strong>FREE</strong>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <button 
                            type="button" 
                            className="btn btn-light fw-semibold" 
                            onClick={this.hideUpgrade}
                        >
                            Close
                        </button>
                        <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                            Upgrade Paket
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}

export default withAuth(Profile)