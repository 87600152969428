import React, { Component } from 'react'
import update from "immutability-helper"
import classNames from "classnames"
import { Link, withRouter } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, remove_login_data } from '../../../config/config'
import { Line } from "react-chartjs-2"
import Avatar from '../../../component/ui/avatar'
import MonthPickerInput from "react-month-picker-input"
import ReactDatePicker from 'react-datepicker'


const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          },
        },
      ]
    },
    plugins:{
        legend:{
            display:false
        }
    }
};

class KpiDetail extends Component {
    state={
        user:{
            saw_skor:0,
            saw_tingkat_tugas:0,
            skor_akhir:0,
            saw_absensi:0
        },
        grafik:{
            labels:[],
            datasets:[
                {
                    label: 'Nilai Akhir',
                    data: [],
                    fill: false,
                    pointBorderWidth:5,
                    backgroundColor: '#0085ff',
                    borderColor: '#0085ff',
                    tension:.7
                }
            ]
        },
        filter:{
            tgl:""
        }
    }

    componentDidMount=()=>{
        this.getRatingUser()
        this.setFilter()
    }
    getRatingUser=()=>{
        API.post("/dashboard/users/get_rating_user", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            tgl_mulai:"",
            tgl_selesai:"",
            id_user:this.props.match.params.id_user
        }))
        .then(res=>{
            this.setState({
                user:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getGrafikRatingUser=()=>{
        API.post("/dashboard/users/get_grafik_rating_user", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            bulan:this.state.filter.tgl.getMonth()+1,
            tahun:this.state.filter.tgl.getFullYear(),
            id_user:this.props.match.params.id_user
        }))
        .then(res=>{
            const labels=res.data.data.map((d, key)=>{
                return key+1
            })
            
            const nilai_akhir=res.data.data.map(d=>{
                return (d.skor_akhir*100).toFixed(0)
            })

            this.setState({
                grafik:update(this.state.grafik, {
                    labels:{$set:labels},
                    datasets:{
                        [0]:{
                            data:{$set:nilai_akhir}
                        }
                    }
                })
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    setFilter=()=>{
        this.setState({
            filter:{
                tgl:new Date()
            }
        }, ()=>{
            this.getGrafikRatingUser()
        })
    }
    
    //filter
    typeFilter=(date)=>{
        this.setState({
            filter:{
                tgl:date
            }
        }, ()=>{
            this.getGrafikRatingUser()
        })
    }


    render() {
        const {user, filter, grafik}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card widget-kpi-detail">
                                    <div class="card-header d-flex justify-content-between">
                                        <h5 className="card-title text-truncate">Key Performance Indicator</h5>
                                    </div>
                                    <div class="card-body p-0 mb-4">
                                        <div class="cover text-center bg-gray">
                                            <span class="avatar avatar-xxl">
                                                <Avatar data={user} circle/>
                                            </span>
                                        </div>
                                        <div class="description text-center mb-5">
                                            <h3 class="fw-bold fs-6 mb-1">{user.nama_lengkap}</h3>
                                            <div class="text-muted fs-8">{user.jabatan}</div>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 py-5 mb-4">
                                        <div className="row">
                                            <div className="col-3 d-flex flex-column align-items-center">
                                                <span class="fs-7 fw-semibold">Absensi</span>
                                                <span className="fw-bold fs-1 text-primary mt-2">{user.saw_absensi}</span>
                                            </div>
                                            <div className="col-3 d-flex flex-column align-items-center">
                                                <span class="fs-7 fw-semibold">Penilaian Atasan</span>
                                                <span className="fw-bold fs-1 text-primary mt-2">{(user.saw_skor*100).toFixed(2)}</span>
                                            </div>
                                            <div className="col-3 d-flex flex-column align-items-center">
                                                <span class="fs-7 fw-semibold">Tingkat Tugas</span>
                                                <span className="fw-bold fs-1 text-primary mt-2">{user.saw_tingkat_tugas}</span>
                                            </div>
                                            <div className="col-3 d-flex flex-column align-items-center">
                                                <span class="fs-7 fw-semibold">Nilai Akhir</span>
                                                <span className="fw-bold fs-1 text-primary mt-2">{(user.skor_akhir*100).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-4 mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="fw-semibold fs-5">Grafik Performa</h3>
                                            <div className="d-flex align-items-center">
                                                <span className="text-nowrap fw-semibold me-2">Bulan :</span>
                                                <ReactDatePicker
                                                    className="form-control"
                                                    selected={filter.tgl}
                                                    onChange={(date)=>this.typeFilter(date)}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    showFullMonthYearPicker
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <Line data={grafik} options={options}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(withAuth(KpiDetail))