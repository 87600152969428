import moment from 'moment'
import React, { Component } from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, remove_login_data } from '../../../config/config'
import { datetime } from '../../../config/datetime'

class RiwayatTugas extends Component {
    state={
        tgl_riwayat:"",
        tugas:[],
        is_loading:true
    }

    componentDidMount=()=>{
        const params=new URLSearchParams(window.location.search)
        
        this.setState({
            tgl_riwayat:params.get("tgl")
        }, ()=>{
            this.getListRiwayat()
        })
    }
    getListRiwayat=()=>{
        API.post("/dashboard/tugas/gets_riwayat_tugas", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            tgl_riwayat:this.state.tgl_riwayat
        }))
        .then(res=>{
            this.setState({
                tugas:res.data.data,
                is_loading:false
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
            this.setState({
                is_loading:false
            })
        })
    }
    getStatusTugas=status=>{
        switch(status){
            case "progress":
                return <span className="badge bg-azure align-self-start px-3">Sedang Dikerjakan</span>
            break;
            case "problem":
                return <span className="badge bg-red align-self-start px-3">Ada Masalah</span>
            break;
            case "delivery":
                return <span className="badge bg-purple align-self-start px-3">Mohon Dicek</span>
            break;
            case "complete":
                return <span className="badge bg-green align-self-start px-3">Selesai</span>
            break;
            case "continue":
                return <span  className="badge bg-blue align-self-start px-3">Lanjutkan</span>
            break;
        }
    }

    render() {
        const {tgl_riwayat, tugas, is_loading}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="card widget-list-tugas">
                                            <div class="card-header d-flex flex-column flex-md-row justify-content-between">
                                                <h5 className="card-title text-truncate">Riwayat Tugas</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-inline-block p-3 bg-white">
                                                        <div className="d-flex align-items-center">
                                                            <FaCalendarAlt className="fs-5 text-primary"/>
                                                            <span className="ms-3">{moment(tgl_riwayat).format("dddd, Do MMMM YYYY")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!is_loading&&
                                                <>
                                                    {tugas.length>0?
                                                        <div className="row mt-4">
                                                            {tugas.map(task=>(
                                                                <div className="col-md-6">
                                                                    <div class="card-body p-4 mb-4">
                                                                        <div className="d-flex align-items-center">
                                                                            <div class="flex-grow-1 ms-4">
                                                                                <div className="d-flex align-items-center text-muted fs-7 mb-2">
                                                                                    <FiSettings/>
                                                                                    <span className="ms-2">{task.kategori}</span>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between w-100">
                                                                                    <h2 className="fs-5 fw-bold">
                                                                                        <Link to="/detail_tugas" className="link-dark">{task.nama_tugas}</Link>
                                                                                    </h2>
                                                                                </div>
                                                                                <div className="d-flex flex-column mt-2">
                                                                                    <span className="text-muted">Deadline</span>
                                                                                    <span class="badge rounded-pill bg-primary align-self-start mt-1 px-3">{datetime(task.deadline)}</span>
                                                                                </div>
                                                                                <div className="d-flex flex-column w-75 mt-2">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <span className="text-muted fs-7">Progress</span>
                                                                                        <span>{task.progress_activity}%</span>
                                                                                    </div>
                                                                                    <div className="mt-1">
                                                                                        <div class="progress" style={{height:"4px"}}>
                                                                                            <div class="progress-bar" role="progressbar" style={{width:task.progress_activity+"%"}}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex flex-column mt-2">
                                                                                    <span className="text-muted fs-7">Status</span>
                                                                                    {this.getStatusTugas(task.status_activity)}
                                                                                </div>
                                                                                <div className="d-flex flex-column mt-2">
                                                                                    <span className="text-muted fs-7">Submit At</span>
                                                                                    {datetime(task.created_at_activity)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    :
                                                        <div className="row mt-4">
                                                            <div className="col-12 text-center">
                                                                <div className="d-flex flex-column align-items-center justify-content-center py-5">
                                                                    <img src="/img/blank.png" className="img-fluid" style={{width:"180px"}}/>
                                                                    <span className="text-muted">Tidak ada riwayat tugas yang dikumpulkan pada tanggal ini!</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            

                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth(RiwayatTugas)