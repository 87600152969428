import React, { lazy, Suspense } from "react";
import { Offcanvas } from "react-bootstrap";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { API } from "./config/api";
import { get_instansi_selected, is_login, login_data } from "./config/config"
import moment from "moment";
import 'moment/locale/id'

//pages
import Authentication from "./pages/auth"
import Dashboard from "./pages/dashboard";



moment.locale("id")
class App extends React.Component{
    state={
        install_prompt:false,
        deferred_prompt:"",
        is_login:false,
        is_loading:true
    }

    componentDidMount=()=>{
        //service worker
        if('serviceWorker' in navigator){
            navigator.serviceWorker.register("/service-worker.js")
            .then(sw=>{
              console.log("service worker registered")
            })
            .catch(e=>{
              console.log("service worker error : ", e)
            })
        }

        //confirm login
        if(is_login()){
            API.post("/dashboard/authentication/verify_token", JSON.stringify({
                token:login_data().access_token
            }))
            .then(res=>{
                const user_data=login_data()
                if(user_data.status!==res.data.data.status){
                    const new_user_data={
                        ...user_data, 
                        ...{status:res.data.data.status}
                    }
                    localStorage.setItem("user_data", JSON.stringify(new_user_data))
                }
                this.setState({
                    is_loading:false,
                    is_login:true
                })
            })
            .catch(err=>{
                this.setState({
                    is_loading:false,
                    is_login:false
                })
            })
        }
        else{
            this.setState({
                is_loading:false,
                is_login:false
            })
        }

        //install prompt
        window.addEventListener("beforeinstallprompt", this.fireAddToHomeScreenImpression)
    }

    //add to home screen
    fireAddToHomeScreenImpression=e=>{
        e.preventDefault()
        this.setState({
            install_prompt:true,
            deferred_prompt:e
        })
    }
    showAddToHomeScreen=async()=>{
        this.state.deferred_prompt.prompt()
        let outcome = await this.state.deferred_prompt.userChoice
        
        if(outcome==='accepted') console.log('User accepted the A2HS prompt')
        else console.log('User dismissed the A2HS prompt')

        this.setState({
            deferred_prompt:null,
            install_prompt:false
        })
    }
    hidePrompt=()=>{
        this.setState({
            install_prompt:false
        })
    }

    render(){
        const {is_loading, is_login, install_prompt, deferred_prompt}=this.state

        return (
            <>
                {!is_loading&&
                    <>
                        {!is_login?
                            <Authentication {...this.props}/>
                        :
                            <Dashboard {...this.props} user={login_data()} instansi_selected={get_instansi_selected()}/>
                        }
                    </>
                }

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    newestOnTop={false}
                    rtl={false}
                    closeOnClick
                    hideProgressBar
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <Offcanvas show={install_prompt} onHide={this.hidePrompt} placement="bottom" className="install-prompt">
                    <Offcanvas.Body>
                        <div className="col-md-10 full-content mx-auto">
                            <div className="d-flex align-items-center justify-content-between full-content">
                                <h3 className="mb-0 me-4 fw-semibold">Install Aplikasi Garapan ID di perangkat anda!</h3>
                                <div className="d-flex flex-column flex-column flex-sm-row" style={{minWidth:"120px"}}>
                                    <button 
                                        className="btn btn-outline-secondary btn-lg text-decoration-none fw-bold me-0 me-sm-2"
                                        onClick={this.hidePrompt}
                                    >
                                        Tutup Prompt
                                    </button>
                                    <button 
                                        className="btn btn-primary btn-lg fw-bold mt-2 mt-sm-0"
                                        onClick={this.showAddToHomeScreen}
                                    >
                                        Install Aplikasi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}

export default withRouter(App)