import React, { Component } from 'react'
import qs from "querystring"
import classNames from "classnames"
import update from "immutability-helper"
import { Modal, Offcanvas, OverlayTrigger, Popover } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { BiUpload } from 'react-icons/bi'
import { BsArrowLeft } from 'react-icons/bs'
import { FiCheck, FiX } from "react-icons/fi"
import Toggle from 'react-toggle'
import withAuth from '../../../component/hoc/auth'
import {FiPlus} from "react-icons/fi"
import { API } from '../../../config/api'
import { access_token, BASE_URL, get_instansi_selected, remove_login_data, isUndefined } from '../../../config/config'
import Avatar from '../../../component/ui/avatar'
import { CSSTransition } from 'react-transition-group';
import DocumentIcon from '../../../component/ui/documen_icon'


class AddTugas extends Component{
    state={
        tambah_tugas:{
            id_tugas_kategori:"",
            nama_tugas:"",
            prioritas:false,
            tingkat_tugas:"ringan",
            penerima:{id_user:""},
            dokumen:[],
            deskripsi:"",
            deadline_tgl:"",
            deadline_time:"00:00"
        },
        upload_progress:{
            is_show:false,
            progress:0
        },
        form_validation_tambah_tugas:{},
        tambah_tugas_besar:{
            is_open:false,
            kategori:""
        },
        form_validation_tambah_tugas_besar:{},
        tambah_penerima:{
            is_open:false
        },
        tugas_besar:[],
        bawahan:[],
        is_loading:false
    }

    componentDidMount=()=>{
        this.getListTugasBesar()
        this.getListBawahan()
        this.setDeadline()
    }
    getListTugasBesar=()=>{
        API.post("/dashboard/tugas_kategori/gets_option_tugas_kategori", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                tugas_besar:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()  
                break;
            }
        })
    }
    getListBawahan=()=>{
        API.post("/dashboard/jabatan/get_list_bawahan", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                bawahan:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()  
                break;
            }
        })
    }
    setDeadline=()=>{
        const day=new Date()
        day.setDate(day.getDate()+1)
        const date=day.getDate().toString().padStart(2, 0)
        const month=(day.getMonth()+1).toString().padStart(2, 0)
        this.setState({
            tambah_tugas:update(this.state.tambah_tugas, {
                deadline_tgl:{$set:day.getFullYear()+"-"+month+"-"+date},
                deadline_time:{$set:"00:00"}
            })
        })
    }
    
    //upload progress
    progressFile=(progress)=>{
        this.setState(prevState=>{
            return {
                ...prevState,
                upload_progress:{
                    ...prevState.upload_progress,
                    is_show:true,
                    progress:progress
                }
            }
        })
    }
    closeProgressFile=()=>{
        this.setState(prevState=>{
            return {
                ...prevState,
                upload_progress:{
                    ...prevState.upload_progress,
                    is_show:false,
                    progress:0
                }
            }
        })
    }

    //tambah tugas
    typeTambahTugas=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            tambah_tugas:update(this.state.tambah_tugas, {
                [target.name]:{$set:value}
            })
        })
    }
    uploadFiles=(files)=>{
        const form_data=new FormData()

        for(var i=0;i<files.length;i++){
            form_data.append("dokumen["+i+"]", files[i])
        }
        form_data.append("token", access_token())

        API.post("/dashboard/file_manager/upload_file", form_data, {
            headers:{
                'content-type':'multipart/form-data'
            },
            onUploadProgress:data=>{
                const progress=Math.round((100 * data.loaded) / data.total)
                this.progressFile(progress)
            }
        })
        .then(res=>{
            const dokumen=this.state.tambah_tugas.dokumen.concat(res.data.dokumen)
            this.setState({
                tambah_tugas:update(this.state.tambah_tugas, {
                    dokumen:{$set:dokumen}
                })
            })
            this.closeProgressFile()
        })
        .catch(err=>{
            if(err.response.status===401){
               remove_login_data()
            }
        })
    }
    removeFile=(doc)=>{
        API.post("/dashboard/file_manager/remove_file", qs.stringify({
            token:access_token(),
            file_name:doc.path
        }))
        .then(res=>{
            const dokumen=this.state.tambah_tugas.dokumen.filter(dok=>dok!==doc)

            this.setState({
                tambah_tugas:update(this.state.tambah_tugas, {
                    dokumen:{$set:dokumen}
                })
            })
        })
        .catch(err=>{
            if(err.response.status===401){
                remove_login_data()
            }
        })
    }
    tambahTugas=(e)=>{
        e.preventDefault()

        const {tambah_tugas}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/tugas/add_tugas", JSON.stringify({
            token:access_token(),
            id_tugas_kategori:tambah_tugas.id_tugas_kategori,
            nama_tugas:tambah_tugas.nama_tugas,
            prioritas:tambah_tugas.prioritas,
            dokumen:tambah_tugas.dokumen,
            tingkat_tugas:tambah_tugas.tingkat_tugas,
            deskripsi:tambah_tugas.deskripsi,
            deadline:tambah_tugas.deadline_tgl+" "+tambah_tugas.deadline_time,
            penerima:tambah_tugas.penerima
        }))
        .then(res=>{
            this.props.history.push(`/tugas/detail/${res.data.id_tugas}`)
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_tugas:err.response.data.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //tambah proyek
    showTambahTugasBesar=(e)=>{
        e.preventDefault();

        this.setState({
            tambah_tugas_besar:update(this.state.tambah_tugas_besar, {
                is_open:{$set:true},
                nama_proyek:{$set:""}
            })
        })
    }
    hideTambahTugasBesar=()=>{
        this.setState({
            tambah_tugas_besar:update(this.state.tambah_tugas_besar, {
                is_open:{$set:false},
                nama_proyek:{$set:""}
            })
        })
    }
    typeTambahTugasBesar=(e)=>{
        const target=e.target

        this.setState({
            tambah_tugas_besar:update(this.state.tambah_tugas_besar, {
                [target.name]:{$set:target.value}
            })
        })
    }
    tambahTugasBesar=(e)=>{
        e.preventDefault()

        this.setState({is_loading:true})
        API.post("/dashboard/tugas_kategori/add_tugas_kategori", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            kategori:this.state.tambah_tugas_besar.kategori
        }))
        .then(res=>{
            this.setState({
                tambah_tugas:update(this.state.tambah_tugas, {
                    id_tugas_kategori:{$set:res.data.id_tugas_kategori}
                }),
                is_loading:false
            })
            this.hideTambahTugasBesar()
            this.getListTugasBesar()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_tugas_besar:err.response.data.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //tambah penerima
    showTambahPenerima=(e)=>{
        e.preventDefault();

        this.setState({
            tambah_penerima:update(this.state.tambah_penerima, {
                is_open:{$set:true}
            })
        })
    }
    hideTambahPenerima=()=>{
        this.setState({
            tambah_penerima:update(this.state.tambah_penerima, {
                is_open:{$set:false}
            })
        })
    }
    addPenerima=user=>{
        this.setState({
            tambah_tugas:update(this.state.tambah_tugas, {
                penerima:{$set:user}
            })
        })
    }
    removePenerima=user=>{
        this.setState({
            tambah_tugas:update(this.state.tambah_tugas, {
                penerima:{$set:[]}
            })
        })
    }

    render() {
        const {tambah_tugas_besar, tambah_penerima, tambah_tugas, tugas_besar, bawahan, form_validation_tambah_tugas_besar, form_validation_tambah_tugas, is_loading, upload_progress}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card widget-tambah-tugas">
                                    <div class="card-header d-flex justify-content-between">
                                        <button className="btn btn-secondary" onClick={()=>this.props.history.goBack()}>
                                            <BsArrowLeft/> Kembali
                                        </button>
                                        <h5 className="card-title text-truncate">Tambah Tugas</h5>
                                    </div>
                                    <div className="col-md-12 mx-auto">
                                        <form class="card-body p-4 mb-4" onSubmit={this.tambahTugas}>
                                            <div class="row mb-3">
                                                <label for="input-proyek" class="col-12 col-form-label fw-bold">Tugas Besar</label>
                                                <div class="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-9 mb-2">
                                                            <OverlayTrigger
                                                                trigger="focus"
                                                                placement="right"
                                                                overlay={
                                                                    !isUndefined(form_validation_tambah_tugas.id_tugas_kategori)?
                                                                    <Popover id="popover-username" className="popover-danger">
                                                                        <Popover.Body>
                                                                            <span class="text-white">Tugas Besar Harus diisi!</span>
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                    :<></>
                                                                }
                                                            >
                                                                <select 
                                                                    id="input-kategori"
                                                                    class={classNames("form-select", {"is-invalid":!isUndefined(form_validation_tambah_tugas.id_tugas_kategori)})}
                                                                    name="id_tugas_kategori"
                                                                    value={tambah_tugas.id_tugas_kategori}
                                                                    onChange={this.typeTambahTugas}
                                                                >
                                                                    <option value="">-- Pilih Tugas Besar</option>
                                                                    {tugas_besar.map(project=>(
                                                                        <option value={project.id_tugas_kategori}>{project.kategori}</option>
                                                                    ))}
                                                                </select>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="col-4 col-sm-3 d-grid grip-2 mb-2">
                                                            <button type="button" className="btn btn-primary" onClick={this.showTambahTugasBesar}>
                                                                <FiPlus/> Tugas Besar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                            <label for="input-tingkat" class="col-12 col-form-label fw-bold">Tingkat Tugas</label>
                                                <div className="col-sm-12">
                                                    <OverlayTrigger
                                                        trigger="focus"
                                                        placement="right"
                                                        overlay={
                                                            !isUndefined(form_validation_tambah_tugas.tingkat_tugas)?
                                                            <Popover id="popover-username" className="popover-danger">
                                                                <Popover.Body>
                                                                    <span class="text-white">Tingkat Tugas Harus diisi!</span>
                                                                </Popover.Body>
                                                            </Popover>
                                                            :<></>
                                                        }
                                                    >
                                                        <select 
                                                            id="input-tingkat" 
                                                            name="tingkat_tugas" 
                                                            className={classNames("form-select", {"is-invalid":!isUndefined(form_validation_tambah_tugas.tingkat_tugas)})} 
                                                            value={tambah_tugas.tingkat_tugas} 
                                                            onChange={this.typeTambahTugas}
                                                        >
                                                            <option value="ringan">Ringan</option>
                                                            <option value="sedang">Sedang</option>
                                                            <option value="berat">Berat</option>
                                                        </select>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="input-namatugas" class="col-12 col-form-label fw-bold">Nama Tugas Kecil</label>
                                                <div class="col-sm-12">
                                                    <OverlayTrigger
                                                        trigger="focus"
                                                        placement="right"
                                                        overlay={
                                                            !isUndefined(form_validation_tambah_tugas.nama_tugas)?
                                                            <Popover id="popover-username" className="popover-danger">
                                                                <Popover.Body>
                                                                    <span class="text-white">Nama Tugas Harus diisi!</span>
                                                                </Popover.Body>
                                                            </Popover>
                                                            :<></>
                                                        }
                                                    >
                                                        <input 
                                                            id="input-namatugas" 
                                                            type="text" 
                                                            className={classNames("form-control", {"is-invalid":!isUndefined(form_validation_tambah_tugas.nama_tugas)})}
                                                            placeholder="Nama Tugas?"
                                                            name="nama_tugas"
                                                            value={tambah_tugas.nama_tugas}
                                                            onChange={this.typeTambahTugas}
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="input-prioritas" class="col-12 col-form-label fw-bold">Prioritas</label>
                                                <div class="col-sm-12">
                                                    <label className="d-flex align-items-center">
                                                        <Toggle
                                                            id="input-prioritas"
                                                            name="prioritas"
                                                            defaultChecked={tambah_tugas.prioritas}
                                                            icons={false}
                                                            onChange={this.typeTambahTugas}
                                                        />
                                                        <span className="ms-2">Prioritaskan Tugas</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="inputEmail3" class="col-12 col-form-label fw-bold">Penerima</label>
                                                <div class="col-sm-12">
                                                    <div className="d-flex justify-content-between">
                                                        <button 
                                                            type="button"
                                                            className="btn btn-primary" 
                                                            onClick={this.showTambahPenerima}
                                                        >
                                                            Tambah Penerima
                                                        </button>
                                                        {tambah_tugas.penerima.id_user!==""&&
                                                            <div className="d-flex align-items-center">
                                                                <span className="me-2">{tambah_tugas.penerima.nama_lengkap}</span>
                                                                <span className="avatar avatar-smd rounded">
                                                                    <Avatar data={tambah_tugas.penerima} circle/>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <label for="inputEmail3" class="col-form-label fw-bold">Dokumen</label>
                                                    <CSSTransition
                                                        in={upload_progress.is_show}
                                                        timeout={300}
                                                        classNames="tg-zoom"
                                                        unmountOnExit
                                                    >
                                                        <div>
                                                            Mengupload : {upload_progress.progress}%
                                                        </div>
                                                    </CSSTransition>
                                                </div>
                                                <div class="col-sm-12">
                                                    <Dropzone onDrop={acceptedFiles=>this.uploadFiles(acceptedFiles)}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <div className="form-upload d-flex flex-column rounded align-items-center p-4" {...getRootProps()}>
                                                                <input 
                                                                    {...getInputProps()}
                                                                />
                                                                <BiUpload className="fs-1"/>
                                                                <span className="fs-8 mt-3">Drag & Drop or Browse</span>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    
                                                    {tambah_tugas.dokumen.length>0&&
                                                        <div className="bg-gray-lt mt-3 p-3">
                                                            {tambah_tugas.dokumen.map(doc=>(
                                                                <div className="d-flex justify-content-between mt-1 align-items-center">
                                                                    <a href={BASE_URL+"/"+doc.path} target="_blank" className="text-truncate d-flex align-items-center">
                                                                        <DocumentIcon
                                                                            data={doc.path}
                                                                        />
                                                                        <span className="ms-2">{doc.file_name}</span>
                                                                    </a>
                                                                    <button 
                                                                        type="button"
                                                                        className="btn btn-danger btn-sm ms-auto pb-1"
                                                                        onClick={(e)=>this.removeFile(doc)}
                                                                    >
                                                                        x
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label for="input-deskripsi" class="col-12 col-form-label fw-bold">Deskripsi</label>
                                                <div class="col-sm-12">
                                                    <textarea 
                                                        id="input-deskripsi"
                                                        className="form-control" 
                                                        placeholder="Deskripsi?"
                                                        name="deskripsi"
                                                        value={tambah_tugas.deskripsi}
                                                        onChange={this.typeTambahTugas}
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label class="col-12 col-form-label fw-bold">Deadline</label>
                                                <div class="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input 
                                                                type="date" 
                                                                className="form-control"
                                                                name="deadline_tgl"
                                                                value={tambah_tugas.deadline_tgl}
                                                                onChange={this.typeTambahTugas}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <input 
                                                                type="time" 
                                                                className="form-control"
                                                                name="deadline_time"
                                                                value={tambah_tugas.deadline_time}
                                                                onChange={this.typeTambahTugas}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-12">
                                                    <div className="d-flex justify-content-center justify-content-sm-end mt-3">
                                                        <button type="submit" className="btn btn-primary" disabled={is_loading}>Simpan Tugas</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* tambah proyek */}
                <Modal show={tambah_tugas_besar.is_open} onHide={this.hideTambahTugasBesar} dialogClassName="modal-smd">
                    <form onSubmit={this.tambahTugasBesar}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold fs-5">Tambah Tugas Besar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label for="input-tugasbesar" class="form-label fw-semibold">Nama Tugas Besar</label>
                            <OverlayTrigger
                                trigger="focus"
                                placement="right"
                                overlay={
                                    !isUndefined(form_validation_tambah_tugas_besar.kategori)?
                                    <Popover id="popover-ddf" className="popover-danger">
                                        <Popover.Body>
                                            <span class="text-white">Nama Tugas Besar Harus diisi!</span>
                                        </Popover.Body>
                                    </Popover>
                                    :<></>
                                }
                            >
                                <input 
                                    type="text" 
                                    class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_tambah_tugas_besar.kategori)})}
                                    id="input-tugasbesar" 
                                    placeholder="Uraian ..."
                                    name="kategori"
                                    value={tambah_tugas_besar.kategori}
                                    onChange={this.typeTambahTugasBesar}
                                />
                            </OverlayTrigger>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between mt-0">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={this.hideTambahTugasBesar}>
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={is_loading}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* tambah penerima */}
                <Offcanvas show={tambah_penerima.is_open} onHide={this.hideTambahPenerima} placement="start">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <h3 className="fs-5 mb-0 fw-bold">Pilih Penerima</h3>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div class="list-group">
                            {bawahan.map(bot=>(
                                <div class="list-group-item d-flex align-items-center mb-2 border-0 px-0 border-bottom">
                                    <span className="avatar avatar-xmd">
                                        <Avatar data={bot} circle/>
                                    </span>
                                    <div className="mx-3">
                                        <h4 className="fs-7 fw-bold mb-0">
                                            <span href="" className="text-dark">{bot.nama_lengkap}</span>
                                        </h4>
                                        <span className="text-muted fs-8">{bot.nama_jabatan}</span>
                                    </div>
                                    <div className="ms-auto">
                                        {tambah_tugas.penerima.id_user===bot.id_user?
                                            <button 
                                                className="btn btn-circle-icon bg-success text-white d-flex align-items-center justify-content-center"
                                                onClick={()=>this.removePenerima(bot)}
                                            >
                                                <FiX/>
                                            </button>
                                        :
                                            <button 
                                                className="btn btn-circle-icon bg-gray text-dark d-flex align-items-center justify-content-center"
                                                onClick={()=>this.addPenerima(bot)}
                                            >
                                                <FiCheck/>
                                            </button>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}

export default withAuth(AddTugas)