import React, { Component } from 'react'
import classNames from "classnames"
import update from "immutability-helper"
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { FiClock, FiEdit, FiX } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, isUndefined, remove_login_data, user_id } from '../../../config/config'
import Avatar from '../../../component/ui/avatar'
import {datetime} from "../../../config/datetime"

class TugasBesar extends Component {
    state={
        proyek:[],
        edit_tugas_besar:{
            is_open:false,
            kategori:"",
            id_tugas_kategori:""
        },
        form_validation_edit_tugas_besar:{},
        is_loading:false
    }

    componentDidMount=()=>{
        this.getAllTugasBesar()
    }
    getAllTugasBesar=()=>{
        API.post("/dashboard/tugas_kategori/gets_tugas_kategori", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                proyek:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    //edit proyek
    showEditTugasBesar=(data)=>{
        this.setState({
            edit_tugas_besar:update(this.state.edit_tugas_besar, {
                is_open:{$set:true},
                id_tugas_kategori:{$set:data.id_tugas_kategori},
                kategori:{$set:data.kategori}
            }),
            form_validation_edit_tugas_besar:{}
        })
    }
    hideEditTugasBesar=()=>{
        this.setState({
            edit_tugas_besar:update(this.state.edit_tugas_besar, {
                is_open:{$set:false},
                id_tugas_kategori:{$set:""},
                kategori:{$set:""}
            })
        })
    }
    typeEditTugasBesar=(e)=>{
        const target=e.target

        this.setState({
            edit_tugas_besar:update(this.state.edit_tugas_besar, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateTugasBesar=(e)=>{
        e.preventDefault()

        this.setState({is_loading:true})
        API.post("/dashboard/tugas_kategori/update_tugas_kategori", JSON.stringify({
            token:access_token(),
            id_tugas_kategori:this.state.edit_tugas_besar.id_tugas_kategori,
            kategori:this.state.edit_tugas_besar.kategori
        }))
        .then(res=>{
            this.setState({
                is_loading:false
            })
            this.hideEditTugasBesar()
            this.getAllTugasBesar()
        })
        .catch(err=>{
            switch (err.response.data.err_code) {
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()    
                break;
                case "ERR_FIELD_REQUIRED":
                    this.setState({
                        form_validation_edit_tugas_besar:err.response.data.data
                    })
                break;
            }
        })
    }

    render() {
        const {proyek, edit_tugas_besar, form_validation_edit_tugas_besar, is_loading}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="card widget-list-tugas">
                                            <div class="card-header d-flex flex-column flex-md-row justify-content-between">
                                                <nav class="nav nav-pills nav-fill bg-white p-1 rounded">
                                                    <Link to="/tugas" className="nav-link">Semua Tugas</Link>
                                                    <Link to="/proyek" className="nav-link active">Semua Tugas Besar</Link>
                                                </nav>
                                                <h5 className="card-title text-truncate d-none d-md-inline-block">Semua Tugas</h5>
                                            </div>
                                            <div className="row">
                                                {proyek.map(project=>(
                                                    <div className="col-md-6">
                                                        <div class="card-body p-4 mb-4">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0">
                                                                    <img src="/img/project.png" className="img-project"/>
                                                                </div>
                                                                <div class="flex-grow-1 ms-4">
                                                                    <h2 className="fs-5 fw-bold mb-3">
                                                                        <Link to={`/tugas_besar/detail/${project.id_tugas_kategori}`} className="link-dark">{project.kategori}</Link>
                                                                    </h2>
                                                                    <span class="badge rounded-pill bg-gray-lt text-dark fw-semibold px-3 mb-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <FiClock className="me-2 fs-7 text-primary"/>
                                                                            {datetime(project.created_at)}
                                                                        </div>
                                                                    </span>
                                                                    <div className="d-flex justify-content-end">
                                                                        <div class="avatar-list-stacked">
                                                                            {project.users.map(user=>(
                                                                                <span className="avatar">
                                                                                    <Avatar data={user} circle/>
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {project.id_user===user_id()&&
                                                                    <div className="d-flex flex-column justify-content-start align-items-start align-self-start">
                                                                        <div className="align-self-start ms-3">
                                                                            <button className="btn p-0 link-secondary fs-5" onClick={e=>this.showEditTugasBesar(project)}>
                                                                                <FiEdit/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* edit proyek */}
                <Modal show={edit_tugas_besar.is_open} onHide={this.hideEditTugasBesar} dialogClassName="modal-smd">
                    <form onSubmit={this.updateTugasBesar}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold fs-5">Edit Tugas Besar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label for="input-kategori" class="form-label fw-semibold">Nama Tugas Besar</label>
                            <OverlayTrigger
                                trigger="focus"
                                placement="right"
                                overlay={
                                    !isUndefined(form_validation_edit_tugas_besar.kategori)?
                                    <Popover id="popover-ddf" className="popover-danger">
                                        <Popover.Body>
                                            <span class="text-white">Nama Tugas Besar Harus diisi!</span>
                                        </Popover.Body>
                                    </Popover>
                                    :<></>
                                }
                            >
                                <input 
                                    type="text" 
                                    class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_edit_tugas_besar.kategori)})}
                                    id="input-kategori" 
                                    placeholder="Uraian ..."
                                    name="kategori"
                                    value={edit_tugas_besar.kategori}
                                    onChange={this.typeEditTugasBesar}
                                />
                            </OverlayTrigger>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between mt-0">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={this.hideEditTugasBesar}>
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={is_loading}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}

export default withAuth(TugasBesar)