import React, { Component } from 'react'
import classNames from "classnames"
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, remove_login_data } from '../../../config/config'
import Avatar from '../../../component/ui/avatar'

class Kpi extends Component {
    state={
        users:[],
        filter:{
            tgl_mulai:"",
            tgl_selesai:""
        }
    }

    componentDidMount=()=>{
        this.getRatingUsers()
    }
    getRatingUsers=()=>{
        const {filter}=this.state

        API.post("/dashboard/users/rating_users", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            tgl_mulai:filter.tgl_mulai,
            tgl_selesai:filter.tgl_selesai
        }))
        .then(res=>{
            this.setState({
                users:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //filter
    typeFilter=(e)=>{
        const target=e.target

        this.setState(prevState=>{
            return {
                ...prevState,
                filter:{
                    ...prevState.filter,
                    [target.name]:target.value
                }
            }
        }, ()=>{
            this.getRatingUsers()
        })
    }


    render() {
        const {users, filter}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card widget-kpi">
                                    <div class="card-header d-flex flex-column flex-sm-row justify-content-between">
                                        <h5 className="card-title text-truncate">Key Performance Indicator</h5>
                                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center mx-2 mx-md-4">
                                            <span className="me-2 fw-semibold">Tanggal</span>
                                            <div class="" style={{minWidth:"150px"}}>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="tgl_mulai"
                                                    value={filter.tgl_mulai}
                                                    onChange={this.typeFilter}
                                                />
                                            </div>
                                            <span className="mx-2 fw-semibold">Sampai</span>
                                            <div class="ms-0 ms-md-2" style={{minWidth:"150px"}}>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="tgl_selesai"
                                                    value={filter.tgl_selesai}
                                                    onChange={this.typeFilter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-0 mb-3">
                                        <div class="list-group">
                                            {users.map((user, i)=>(
                                                <div class={classNames("list-group-item d-flex align-items-center py-2 border-0", {"bg-gray-lt":i%2===0})}>
                                                    <span className="avatar avatar-xmd">
                                                        <Avatar data={user} circle/>
                                                    </span>
                                                    <div className="mx-3">
                                                        <h4 className="fs-7 fw-bold mb-0">
                                                            <span className="text-dark">{user.nama_lengkap}</span>
                                                        </h4>
                                                        <span className="text-muted fs-8">{user.jabatan}</span>
                                                    </div>
                                                    <div className="ms-auto text-dark" style={{minWidth:"100px"}}>
                                                        Nilai : <span className="fw-semibold">{Number(user.skor_akhir*100).toFixed(2)}</span>
                                                    </div>
                                                    <div className="ms-3">
                                                        <Link to={`/kpi/detail/${user.id_user}`} className="btn btn-outline-primary rounded-pill">Selengkapnya</Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth(Kpi)