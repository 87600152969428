import { Modal } from "react-bootstrap"
import classNames from "classnames"

export const ConfirmDelete=({show, toggle, title, sub_title, deleteAction})=>{
    return (
        <Modal show={show} backdrop="static" onHide={toggle} dialogClassName="modal-smd">
            <Modal.Body className="pb-0">
                <h4 class="fs-5 fw-bold">{title}</h4>
                <p className="mb-0">{sub_title}</p>
            </Modal.Body>
            <Modal.Footer className="mt-2">
                <button type="button" class="btn btn-link link-dark me-auto" onClick={toggle}>Cancel</button>
                <button type="button" class="btn btn-danger" onClick={deleteAction}>Ya, Hapus Data</button>
            </Modal.Footer>
        </Modal>
    )
}

export const Confirm=({show, toggle, title, sub_title, action, action_text="", btn_type="btn-danger"})=>{
    return (
        <Modal show={show} backdrop="static" onHide={toggle} dialogClassName="modal-smd">
            <Modal.Body className="pb-0">
                <h4 class="fs-5 fw-bold">{title}</h4>
                <p className="mb-0">{sub_title}</p>
            </Modal.Body>
            <Modal.Footer className="mt-2">
                <button type="button" class="btn btn-link link-dark me-auto" onClick={toggle}>Cancel</button>
                <button type="button" class={classNames("btn", btn_type)} onClick={action}>{action_text}</button>
            </Modal.Footer>
        </Modal>
    )
}