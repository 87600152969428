import React from "react"
import {Spinner} from "react-bootstrap"

export default function InfiniteLoader(props){
    return (
        <div
            className="d-flex justify-content-center"
            style={{width:"100%", position:"absolute", top:0, left:0}}
        >
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}