import { API } from "./api";

//google client
export const GOOGLE_OAUTH_CLIENT_ID="640064606125-uejvvtkt86e4v2t3csccs1mnfvbgjrek.apps.googleusercontent.com"

//url
export const BASE_URL=process.env.NODE_ENV==="development"?
    "http://localhost/garapanid/public":
    "https://api-v2.garapan.id";

//login data
export const is_login=()=>{
    const user_data=localStorage.getItem("user_data")

    if(user_data!==null){
        return true
    }
    else{
        return false
    }
}
export const login_data=()=>{
    const user_data=localStorage.getItem("user_data")
    return JSON.parse(user_data)
}
export const access_token=()=>{
    if(is_login()){
        return login_data().access_token
    }
    else{
        return ""
    }
}
export const user_id=()=>{
    if(is_login()){
        return login_data().id_user
    }
    else{
        return ""
    }
}
export const remove_login_data=()=>{
    localStorage.removeItem("user_data")
    window.location.href="/?status=token_expired"
}
export const goto_page=page=>{
    window.location.href=page
}
export const get_instansi_selected=()=>{
    const instansi_selected=localStorage.getItem("instansi_selected")

    if(instansi_selected!==null){
        return JSON.parse(instansi_selected)
    }
    else{
        return {id_instansi:"", created_by:"", jabatan_type:[]}
    }
}
export const check_instansi=()=>{
    return new Promise((resolve, reject)=>{
        if(get_instansi_selected().id_instansi===""){
            reject(get_instansi_selected())
        }
        else{
            resolve(get_instansi_selected())
        }
    })
}

//helper
export const validateEmail=email=>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const isUndefined=v=>{
    if(typeof v==="undefined") return true
    else return false
}
export const password_validate=(password, allow_empty=true)=>{
    const re={
        digit:/[0-9]/
    }
    
    if(allow_empty){
        if(password===""){
            return true
        }
    }
    return re.digit.test(password) && password.length>=8
}
export const str_acronym=str=>{
    if(str.length>0){
        var matches=str.match(/\b(\w)/g)
        return matches.join('')
    }
    else{
        return ""
    }
}