import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from "react-helmet"
import Toggle from "react-toggle"
import { FcGoogle } from "react-icons/fc"
import GoogleLogin from 'react-google-login'
import { GOOGLE_OAUTH_CLIENT_ID, goto_page, go_page, reload_homepage } from '../../config/config'
import { API } from '../../config/api'
import { toast } from 'react-toastify'

class Login extends Component {
    state={
        username:"",
        password:"",
        remember:false,
        status:"login",
        is_loading:false
    }

    componentDidMount=()=>{
        this.setStatus()
    }
    setStatus=()=>{
        const params=new URLSearchParams(window.location.search)

        if(this.state.status!==params.get("status")){
            let status
            if(params.get("status")==="failed"){
                status="failed"
                this.inputPassword.current.focus()
            }
            else if(params.get("status")==="logout"){
                status="logout"
            }
            else if(params.get("status")==="token_expired"){
                status="token_expired"
            }
            else{
                status="login"
            }

            this.setState({
                status:status
            })
        }
    }

    //login username password
    typeLogin=(e)=>{
        const target=e.target

        let value
        if(target.type==="checkbox") value=target.checked
        else value=target.value

        this.setState({
            [target.name]:value
        })
    }
    validateLogin=()=>{
        return this.state.is_loading?true:false
    }
    login=(e)=>{
        e.preventDefault()

        const {username, password, remember}=this.state

        this.setState({
            is_loading:true
        })
        API.post("/dashboard/authentication/verify_login", JSON.stringify({
            username:username,
            password:password,
            remember:remember
        }))
        .then(res=>{
            localStorage.setItem("user_data", JSON.stringify(res.data.data))
            if(res.data.instansi!==""){
                localStorage.setItem("instansi_selected", JSON.stringify(res.data.instansi));
            }
            goto_page("/")
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_LOGIN_FAIL":
                    this.props.history.push("/?status=failed")
                break;
            }
            this.setState({
                is_loading:false
            })
        })
    }
    loginGoogle=(tokenID)=>{
        API.post("/dashboard/authentication/verify_login_google", JSON.stringify({
            token:tokenID
        }))
        .then(res=>{
            localStorage.setItem("user_data", JSON.stringify(res.data.data))
            if(res.data.instansi!==""){
                localStorage.setItem("instansi_selected", JSON.stringify(res.data.instansi));
            }
            goto_page("/")
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_LOGIN_FAIL":
                    this.props.history.push("/login?status=failed")
                break;
                case "ERR_EMAIL_USED":
                    toast.error("E-Mail Sudah digunakan, tidak bisa login dengan google!");
                break;
            }
        })
    }

    //refs
    inputPassword=React.createRef()

    render() {
        const {username, password, remember, status}=this.state

        return (
            <>
                <Helmet>
                    <style>
                        {'body{background:#fff}'}
                    </style>
                </Helmet>

                <div className="container px-4">
                    <div className="row align-items-center" style={{minHeight:"100vh"}}>
                        <div className="col-md-4">
                            <h2 className="fw-bold">Masuk</h2>
                            <p className="text-gray">Masukkan Username/E-Mail dan Password untuk Masuk</p>
                            {status==="failed"&&
                                <div class="alert alert-danger" role="alert">
                                    Login Gagal, Silahkan Coba Lagi!
                                </div>
                            }
                            {status==="logout"&&
                                <div class="alert alert-info" role="alert">
                                    Berhasil Logout Sistem!
                                </div>
                            }
                            {status==="token_expired"&&
                                <div class="alert alert-warning" role="alert">
                                    Token Kadaluarsa, Silahkan Login Kembali!
                                </div>
                            }
                            <form className="form-login mt-4" onSubmit={this.login}>
                                <div className="mb-3">
                                    <input 
                                        type="text" 
                                        className="form-control py-2"
                                        placeholder="Username/E-Mail"
                                        onChange={this.typeLogin}
                                        name="username"
                                        value={username}
                                    />
                                </div>
                                <div className="mb-3">
                                    <input 
                                        ref={this.inputPassword} 
                                        type="password" 
                                        className="form-control"
                                        placeholder="Password"
                                        onChange={this.typeLogin}
                                        name="password"
                                        value={password}
                                    />
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            <label className="d-flex align-items-center">
                                                <Toggle
                                                    defaultChecked={remember}
                                                    icons={false}
                                                    name="remember"
                                                    onChange={this.typeLogin}
                                                />
                                                <span className="ms-2">Remember Me</span>
                                            </label>
                                        </span>
                                        <span className="text-gray">
                                            Tidak Punya Akun?
                                            <Link to="/register" className="ms-1">Daftar</Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button type="submit" className="btn btn-primary px-5 py-2" disabled={this.validateLogin()}>Masuk</button>
                                </div>
                                <div className="mb-3">
                                    <div className="block-divider">
                                        <span className="block-divider-text text-gray">Atau Masuk Dengan</span>
                                        <span className="block-divider-line"></span>
                                    </div>
                                </div>
                                <div className="mb-3 d-grid gap-2">
                                    <GoogleLogin
                                        clientId={GOOGLE_OAUTH_CLIENT_ID}
                                        render={renderProps=>(
                                            <button type="button" className="btn btn-outline-secondary py-2" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                <FcGoogle className="me-2 fs-5"/>
                                                Masuk Dengan Google
                                            </button>
                                        )}
                                        onSuccess={response=>this.loginGoogle(response.tokenId)}
                                        onFailure={()=>console.log("failure")}
                                        cookiePolicy={"single_host_origin"}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 ms-auto d-none d-md-flex">
                            <img src="/img/hero-img.png" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Login)