import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'

class BlankPage extends Component {
    render() {
        return (
            <div className="content mt-4 mb-5 mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:"400px"}}>
                                <p className="text-center text-muted">Belum Ada Instansi atau Tugas Apapun, Buat Instansi atau minta undangan Instansi Anda!</p>
                                <Link to="/start_wizard" className="btn btn-primary btn-xl fw-bold fs-7">Buat instansi</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth(BlankPage)